



















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { readLocalidades } from '@/store/localidade/getters';
import { readOrigemLocalidadeLista } from '@/store/origem_localidade/getters';
import { readTiposLocalidade } from '@/store/tipo_localidade/getters';
import { readSubprefeituras } from '@/store/subprefeitura/getters';
import { readDistritos } from '@/store/distrito/getters';
import { readBairros } from '@/store/bairro/getters';
import { readRegioes } from '@/store/regiao/getters';
import { readTiposLogradouro } from '@/store/tipo_logradouro/getters';
import { readResponsaveis } from '@/store/responsavel/getters';
import { readStatusImplantacao } from '@/store/status_implantacao/getters';
import { readTiposPublicidade } from '@/store/tipo_publicidade/getters';
import { dispatchGetLocalidades } from '@/store/localidade/actions';
import { dispatchGetLocalidadesFiltradas } from '@/store/localidade/actions';
import { dispatchDeleteLocalidade } from '@/store/localidade/actions';
import { ILocalidade, ILocalidadeSelectFilter } from '@/interfaces/localidade';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';
import ModalDetalhesLocalidade from '@/components/ModalDetalhesLocalidade.vue';
import cacheDominiosEstaticos from '@/store/localidade/cache';

@Component({
  components: {
    ModalConfirmacao,
    ModalDetalhesLocalidade,
  },
})
export default class ListarLocalidades extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public loadingText = 'Carregando dados...';
  public isTableLoading = true;
  public modalDetalhesLocalidadeAtivo: boolean = false;
  public localidadeExclusao;
  public localidadeDetalhes;
  public show: boolean = false;
  public filtroAtivo = false;
  public filtroCodigoLocalidade: string = '';
  public filtroOrigem: string[] = new Array();
  public filtroResponsavel: string[] = new Array();
  public filtroNome: string = '';
  public filtroReferencia: string = '';
  public filtroStatus: string = '';
  public filtroTipoPublicidade: string[] = new Array();
  public filtroTipoLocalidade: string[] = new Array();
  public filtroSubprefeitura: string[] = new Array();
  public filtroDistrito: string[] = new Array();
  public filtroBairro: string[] = new Array();
  public filtroRegiao: string[] = new Array();
  public filtroEndereco: string = '';
  public filtroPontosAcesso: string = '';
  public localidadesFiltradas;
  public headerOrdenado;
  public filtros: ILocalidadeSelectFilter = {
        codigo_localidade: '', origem: [], reponsavel: [], nome: '', referencia: '', status: '',
        tipo_publicidade: [], tipo_localidade: [], subprefeitura: [], distrito: [], bairro: [],
        zona: [], endereco: '', possui_pontos_acesso: '', ordenacao: false, sentido: '', campoOrdenar: '',
        skip: 0,
      };

  public headers = [
    {
      text: 'Código',
      sortable: false,
      value: 'id',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'codigo',
    },
    {
      text: 'Nome',
      sortable: false,
      value: 'nome',
      align: 'left',
      ordenacao: '',
      chaveFiltro: 'nome',
    },
    {
      text: 'Origem',
      sortable: false,
      value: 'origem_localidade.nome',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'origem',
    },
    {
      text: 'Resp',
      sortable: false,
      value: 'responsavel.sigla',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'responsavel',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'status_implantacao.nome',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'status',
    },
    {
      text: 'Sub',
      sortable: false,
      value: 'subprefeitura.nome',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'subprefeitura',
    },
    {
      text: 'Endereço',
      sortable: false,
      value: 'endereco',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'endereco',
    },
    {
      text: 'Data de inclusão',
      sortable: false,
      value: 'created_at',
      align: 'center',
      ordenacao: '',
      chaveFiltro: 'dt_criacao',
    },
    {
      text: 'Pontos de Acesso',
      sortable: false,
      value: 'pontos_acesso',
      align: 'left',
      ordenacao: '',
      chaveFiltro: 'pontos_acesso',
    },
  ];

  public options = {
    rowsPerPage: 10,
    page: 1,
  };

  public opcoesPontosAcesso = [
    {
      text: 'Possui pontos de acesso',
      value: 'possui_pontos_de_acesso',
    },
    {
      text: 'Não possui pontos de acesso',
      value: 'nao_possui_pontos_de_acesso',
    },
  ];

  public ordenarListaLocalidades(header) {
    if (this.headerOrdenado && this.headerOrdenado !== header) {
      this.headerOrdenado.ordenacao = '';
    }

    if (header.ordenacao === 'arrow_upward') {
      header.ordenacao = 'arrow_downward';
    } else {
      header.ordenacao = 'arrow_upward';
    }
    this.headerOrdenado = header;
    this.aplicarFiltros(true, header.ordenacao, header.chaveFiltro, true);
  }

  public async aplicarFiltros(aplicarOrdenacao, sentido, campoOrdenar, ativarFiltro) {
    this.filtroAtivo = ativarFiltro;

    if (this.headerOrdenado && campoOrdenar === '') {
      this.headerOrdenado.ordenacao = '';
    }
    this.filtros = {
        codigo_localidade: this.filtroCodigoLocalidade,
        origem: this.filtroOrigem,
        reponsavel: this.filtroResponsavel,
        nome: this.filtroNome,
        referencia: this.filtroReferencia,
        status: this.filtroStatus,
        tipo_publicidade: this.filtroTipoPublicidade,
        tipo_localidade: this.filtroTipoLocalidade,
        subprefeitura: this.filtroSubprefeitura,
        distrito: this.filtroDistrito,
        bairro: this.filtroBairro,
        zona: this.filtroRegiao,
        endereco: this.filtroEndereco,
        possui_pontos_acesso: this.filtroPontosAcesso,
        ordenacao: aplicarOrdenacao,
        sentido,
        campoOrdenar,
        skip: 0,
      };
    await dispatchGetLocalidadesFiltradas(this.$store, this.filtros);
    this.show = false;
    this.isTableLoading = false;
    this.loadingText = 'Não foram encontrados registros.';
  }

  public limparFiltros() {
    this.filtroCodigoLocalidade = '';
    this.filtroOrigem = new Array();
    this.filtroResponsavel = new Array();
    this.filtroNome = '';
    this.filtroReferencia = '';
    this.filtroStatus = '';
    this.filtroTipoPublicidade = new Array();
    this.filtroTipoLocalidade = new Array();
    this.filtroSubprefeitura = new Array();
    this.filtroDistrito = new Array();
    this.filtroBairro = new Array();
    this.filtroRegiao = new Array();
    this.filtroEndereco = '';
    this.filtroPontosAcesso = '';
    this.aplicarFiltros(false, '', '', false);
  }

  public async updatePagination(pagination) {
    const totalRegistros = this.localidades.length;
    const itensPorPagina = pagination.rowsPerPage;
    const pagina = pagination.page;

    let paginasCache;
    if (totalRegistros > 0) {
      paginasCache = totalRegistros / itensPorPagina;
      if (paginasCache === pagina) {
        if (this.filtroAtivo) {
          this.filtros.skip = totalRegistros;
          await dispatchGetLocalidadesFiltradas(this.$store, this.filtros);
        } else {
          await dispatchGetLocalidades(this.$store, totalRegistros);
        }
      }
    }
  }

  public irPrimeiraPagina() {
    this.options.page = 1;
  }

  get localidades() {
    return readLocalidades(this.$store);
  }

  get origensLocalidade() {
     return readOrigemLocalidadeLista(this.$store);
  }

  get tiposLocalidade() {
    return readTiposLocalidade(this.$store);
  }

  get subprefeituras() {
    return readSubprefeituras(this.$store);
  }

  get distritos() {
    return readDistritos(this.$store);
  }

  get bairros() {
    return readBairros(this.$store);
  }

  get regioes() {
    return readRegioes(this.$store);
  }

  get tiposLogradouro() {
    return readTiposLogradouro(this.$store);
  }

  get responsaveis() {
    return readResponsaveis(this.$store);
  }

  get statusImplantacao() {
    return readStatusImplantacao(this.$store);
  }

  get tiposPublicidade() {
    return readTiposPublicidade(this.$store);
  }

  public abrirModalExclusao(localidade: ILocalidade) {
    this.localidadeExclusao = localidade;
    this.modalExclusaoAtivo = true;
  }

  public abrirModalDetalhesLocalidade(localidade: ILocalidade) {
    this.localidadeDetalhes = localidade;
    this.modalDetalhesLocalidadeAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteLocalidade(this.$store, this.localidadeExclusao);
    if (excluiu) {
      dispatchGetLocalidades(this.$store, 0);
    }
    this.modalExclusaoAtivo = false;
  }

  public async mounted() {
    await cacheDominiosEstaticos(this.$store);
  }
}
