











































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ILocalidade, ILocalidadeHistoricoAlteracoes } from '@/interfaces/localidade';
import ModalHistoricoLocalidade from '@/components/ModalHistoricoLocalidade.vue';
import { dispatchGetHistoricoLocalidade } from '@/store/localidade/actions';

@Component({
  components: {
    ModalHistoricoLocalidade,
  },
})
export default class Modal extends Vue {
  @Prop(Object) public localidade: object | undefined;
  public exibir: boolean = true;
  public modalHistoricoLocalidadeAtivo: boolean = false;
  public localidadeHistoricoAlteracoes: ILocalidadeHistoricoAlteracoes[] | undefined;

  @Watch('exibir')
  public onExibirChanged(val: boolean, oldVal: boolean) {
    if (!val) {
      this.fecharModal();
    }
  }

  @Emit('modalClose')
  public fecharModal() {
    return;
  }

  @Emit('modalConfirm')
  public confirmar() {
    return;
  }

  public async abrirModalHistoricoLocalidade(localidade: ILocalidade) {
    this.localidadeHistoricoAlteracoes = await dispatchGetHistoricoLocalidade(this.$store, localidade.id);
    this.modalHistoricoLocalidadeAtivo = true;
  }
}
