import { readLocalidades } from '@/store/localidade/getters';
import { readOrigemLocalidadeLista } from '@/store/origem_localidade/getters';
import { readTiposLocalidade } from '@/store/tipo_localidade/getters';
import { readSubprefeituras } from '@/store/subprefeitura/getters';
import { readDistritos } from '@/store/distrito/getters';
import { readBairros } from '@/store/bairro/getters';
import { readRegioes } from '@/store/regiao/getters';
import { readTiposLogradouro } from '@/store/tipo_logradouro/getters';
import { readResponsaveis } from '@/store/responsavel/getters';
import { readStatusImplantacao } from '@/store/status_implantacao/getters';
import { readTiposPublicidade } from '@/store/tipo_publicidade/getters';
import { dispatchGetLocalidades } from '@/store/localidade/actions';
import { dispatchGetOrigemLocalidadeLista } from '@/store/origem_localidade/actions';
import { dispatchGetTiposLocalidade } from '@/store/tipo_localidade/actions';
import { dispatchGetSubprefeituras } from '@/store/subprefeitura/actions';
import { dispatchGetDistritos } from '@/store/distrito/actions';
import { dispatchGetBairros } from '@/store/bairro/actions';
import { dispatchGetRegioes } from '@/store/regiao/actions';
import { dispatchGetTiposLogradouro } from '@/store/tipo_logradouro/actions';
import { dispatchGetResponsaveis } from '@/store/responsavel/actions';
import { dispatchGetStatusImplantacao } from '@/store/status_implantacao/actions';
import { dispatchGetTiposPublicidade } from '@/store/tipo_publicidade/actions';

const cacheDominiosEstaticos = async (store) => {
    const dominiosEstaticos = [
        {
            getter: readLocalidades,
            dispatcher: dispatchGetLocalidades,
        },
        {
            getter: readOrigemLocalidadeLista,
            dispatcher: dispatchGetOrigemLocalidadeLista,
        },
        {
            getter: readTiposLocalidade,
            dispatcher: dispatchGetTiposLocalidade,
        },
        {
            getter: readSubprefeituras,
            dispatcher: dispatchGetSubprefeituras,
        },
        {
            getter: readDistritos,
            dispatcher: dispatchGetDistritos,
        },
        {
            getter: readBairros,
            dispatcher: dispatchGetBairros,
        },
        {
            getter: readRegioes,
            dispatcher: dispatchGetRegioes,
        },
        {
            getter: readTiposLogradouro,
            dispatcher: dispatchGetTiposLogradouro,
        },
        {
            getter: readResponsaveis,
            dispatcher: dispatchGetResponsaveis,
        },
        {
            getter: readStatusImplantacao,
            dispatcher: dispatchGetStatusImplantacao,
        },
        {
            getter: readTiposPublicidade,
            dispatcher: dispatchGetTiposPublicidade,
        },
    ];

    dominiosEstaticos.forEach((dominioEstatico) => {
        if (dominioEstatico.getter(store).length < 1) {
            dominioEstatico.dispatcher(store, 0);
        }
    });
};

export default cacheDominiosEstaticos;
