



















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop(String) public titulo: string | undefined;
  @Prop(String) public texto: string | undefined;
  public exibir: boolean = true;

  @Emit('modalClose')
  public cancelar() {
    return;
  }

  @Emit('modalConfirm')
  public confirmar() {
    return;
  }
}
